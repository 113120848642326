import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"
import MainTitle from "../components/mainTitle"
import BudgetEstimate from "../components/budgetEstimate"
import Arrow from "../images/arrow.svg"
import CarouselProjects from "../components/carouselProjects"

import ScrollAnimation from "react-animate-on-scroll"

import { IndexStyle } from "../styles/index"
import { StartAreaStyle } from "../styles/startArea"
import { colors } from "../styles/variables"

class EletricidadeIndustrial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipServices: false,
      width: 0,
      height: 0,
    }
    this.handleClick = this.handleClick.bind(this)
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  componentDidUpdate() {
    window.addEventListener("resize", this.updateWindowDimensions)
  }
  handleClick() {
    this.setState(prevState => ({
      tooltipServices: !prevState.tooltipServices,
    }))
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  render() {
    const {
      startArea2,
      startArea2_tooltip,
      startArea_carousel,
      budgetEstimate,
    } = this.props.data.dataJson || {}

    return (
      <>
        <SEO
          title="Setor Civil"
          image="/images/eletricidade.jpg"
          imageWidth="1200"
          imageHeight="628"
          pathname="/setor-civil"
          description="A Sicarpo apoia as diferentes áreas de eletricidade industrial na instalação, manutenção e gestão de equipamentos elétricos."
        />

        {this.props.data && this.props.data.dataJson ? (
          <>
            <StartAreaStyle>
              {/* Start - Eletricidade Industrial */}
              <div
                className="startArea"
                style={{ backgroundImage: `url(${startArea2.img.publicURL})` }}
              >
                <div className="text">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    offset={0}
                    duration={1}
                    animateOnce={true}
                  >
                    <h1>{startArea2.title}</h1>
                  </ScrollAnimation>
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    offset={0}
                    duration={1}
                    animateOnce={true}
                    delay={200}
                  >
                    <div className="buttons-container-grid">
                      <Link
                        onClick={this.props.removeHash}
                        to="/setor-civil/#projetos"
                        className="button-wrapper-grid"
                      >
                        <div className="button">
                          <div className="slide"></div>
                          <div className="link">Veja os nossos projetos</div>
                        </div>
                      </Link>
                      <a
                        href="/files/politica_qualidade.pdf"
                        alt=""
                        className="button-wrapper-grid"
                      >
                        <div className="button">
                          <div className="slide"></div>
                          <div className="link">Política de Qualidade</div>
                        </div>
                      </a>
                      <a
                        href="https://www.certipedia.com/quality_marks/9000001621"
                        alt="tuv"
                        className="button-wrapper-grid tuv"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <picture>
                          <source
                            srcSet={startArea2.tuv.publicURL}
                            type="image/png"
                          />
                          <img src={startArea2.tuv.publicURL} alt="tuv" />
                        </picture>
                      </a>
                      {/* <a
                        href="/files/certificado_ISO9001_sicarpo.pdf"
                        alt=""
                        className="button-wrapper"
                      >
                        <div className="button">
                          <div className="slide"></div>
                          <div className="link">Certificado TÜV</div>
                        </div>
                      </a> */}
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
              {/* End   - Eletricidade Industrial */}

              {/* Start - Tooltip */}
              <div className="tooltipArea">
                <ScrollAnimation
                  animateIn="fadeIn"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                  delay={400}
                >
                  <div className="text">
                    {!this.state.tooltipServices ? (
                      <>
                        <h1>{startArea2_tooltip.title}</h1>
                        <div className="content">
                          <p>{startArea2_tooltip.textp1}</p>
                          <ul>
                            <li>Eletricidade</li>
                            <li>Águas e Esgotos</li>
                            <li>Rede de Incêndio</li>
                            <li>Energias Renováveis</li>
                            <li>ITED</li>
                            <li>Gás</li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <>
                        <ScrollAnimation animateIn="fadeInLeft" duration={1}>
                          <h1>{startArea2_tooltip.title}</h1>
                          <div className="content">
                            <p>{startArea2_tooltip.textp1}</p>
                            <ul>
                              <li>Segunda Página</li>
                            </ul>
                          </div>
                        </ScrollAnimation>
                      </>
                    )}
                  </div>
                </ScrollAnimation>
                <Link to="/setor-industrial" className="tooltipButtonContainer">
                  <span className="tooltipButton">
                    Outros serviços <Arrow className="tooltipButtonArrow" />
                  </span>
                </Link>
              </div>
              {/* End   - Tooltip */}

              {/* Start - Carousel */}
              <div id="projetos" className="carouselProjectsContainer">
                <ScrollAnimation
                  animateIn="fadeInLeft"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                >
                  <MainTitle
                    smallText={startArea_carousel.mainTitle.smallText}
                    bigText={startArea_carousel.mainTitle.bigText}
                    colorSmall={colors.titleDarkSmall}
                    colorBig={colors.titleDarkBig}
                    className="title"
                  />
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn="fadeInRight"
                  offset={0}
                  duration={1.2}
                  animateOnce={true}
                  delay={400}
                >
                  <CarouselProjects
                    area="eletricidade"
                    naturalSlideWidth={
                      this.state.width < 415
                        ? 250
                        : this.state.width < 610
                        ? 300
                        : this.state.width < 768
                        ? 270
                        : 132
                    }
                    naturalSlideHeight={
                      this.state.width < 415
                        ? 290
                        : this.state.width < 610
                        ? 360
                        : this.state.width < 768
                        ? 400
                        : 190
                    }
                    visibleSlides={
                      this.state.width < 415
                        ? 1
                        : this.state.width < 610
                        ? 2
                        : this.state.width < 768
                        ? 3
                        : 4
                    }
                  />
                </ScrollAnimation>
              </div>
              {/* End   - Carousel */}
            </StartAreaStyle>
            <IndexStyle>
              {/* Start - Soluções à Medida / Budget */}
              <BudgetEstimate
                bigText={budgetEstimate.mainTitle.bigText}
                bigText2={budgetEstimate.mainTitle.bigText2}
                colorBig={colors.titleLightBig}
                className="titleBudget"
                h1="titleBudgetH1"
                firstParagraph={budgetEstimate.text.firstParagraph}
              />
              {/* End   - Soluções à Medida / Budget */}
            </IndexStyle>
          </>
        ) : (
          <div className="isLoading">A carregar...</div>
        )}
      </>
    )
  }
}

export default EletricidadeIndustrial

export const EletricidadeIndustrialQuery = graphql`
  query EletricidadeIndustrialQuery {
    dataJson {
      budgetEstimate {
        mainTitle {
          smallText
          bigText
          bigText2
        }
        text {
          firstParagraph
        }
      }
      startArea2 {
        img {
          publicURL
        }
        tuv {
          publicURL
        }
        title
      }
      startArea2_tooltip {
        title
        textp1
        textp2
      }
      startArea_carousel {
        mainTitle {
          smallText
          bigText
        }
      }
    }
  }
`
